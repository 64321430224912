<template>
  <el-dialog :title="$t('condominium.unactiveUsersDialog.title')" v-model="showDialog" :close-on-click-modal="false">
    <el-table v-if="userList" :data="userList">
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="fullname" label="Nominativo" width="200">
        <template #default="scope">
          {{ getFullName(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="hashlink" label="Link di attivazione">
        <template #default="scope">
          {{ getHashLink(scope.row)}}
        </template>
      </el-table-column>
      <!-- <el-table-column label="Stato" width="70">
        <template #default="scope">
          <i :class="getActiveIcon(scope.row.active)"></i>
        </template>
      </el-table-column> -->
      <!-- <el-table-column width="70">
        <template #default="scope">
          <el-tooltip class="item" effect="dark" content="Clicca per visualizzare le info dell'utente">
            <el-button @click="userSelected(scope.row)" type="primary" circle size="small" icon="el-icon-d-arrow-right"> </el-button>
          </el-tooltip>
        </template>
      </el-table-column> -->
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">{{$t("buttons.close")}}</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import UserMixin from '../../mixins/UserMixin';

export default {
  name: "ShowUnactiveUsersDialog",
  emits: ["dismiss"],
  mixins: [UserMixin],
  data() {
    return {
      showDialog: false,
      userList: [],
      headers : {
        "name" : "Nominativo",
        "hasUrl" : "URL",
      }
    }
  },
  computed:{
    ...mapGetters(["currentUser"])
  },
  methods: {
    ...mapActions(['deleteUser']),
    show(){
      this.showDialog = true;
      console.log(this.getHashLink(this.userList[0]))
    },
    submit() {
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    async onDeleteConfirm(){
        let data = {
            "id": this.currentUser.id,
            "password":this.password
        }
        this.deleteUser(data);
    },
  }

}
</script>

<style scoped>

</style>

