<template>
  <el-dialog v-model="showDialog" :title="title" width="40%" >
    <el-alert type="warning" :description="message" show-icon :closable="false"></el-alert>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss"> {{$t("buttons.dismiss")}} </el-button>
        <el-button type="warning" @click="submit"> {{$t("buttons.confirm")}} </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      title: "",
      message: ""
    }
  },
  methods: {
    show(){
      this.showDialog = true;
    },
    submit() {
      this.$emit("submit");
      this.showDialog = false;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    }
  }
}
</script>

<style scoped>

</style>