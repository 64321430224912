<template>
  <div>
    <el-row style="margin-bottom: 15px; align-items: center;" justify="space-between">
      <el-col :span="8">
        <h4 style="margin: 0">Riepilogo consumi
          <span v-if="currentCondominium && currentCondominium.lastReadingDate">
            aggiornato al {{ getLastReadingDate() }}
          </span>
        </h4>
      </el-col>
      <el-col :span="8">
        <el-input placeholder="Cerca per utente" v-model="query" @clear="clearInput" clearable></el-input>
      </el-col>
    </el-row>
          <el-form :inline="true" label-width="auto" @submit.prevent>
            <el-form-item label="Periodo">
              <el-date-picker
                  v-model="period"
                  type="daterange"
                  start-placeholder="Data inizio"
                  end-placeholder="Data fine"
                  format="DD/MM/YYYY"
                  value-format="DD/MM/YYYY"
                  range-separator="-"
                  unlink-panels
                  @change="fetchData"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Stagione">
              <el-button-group>
                <el-button type="primary" icon="el-icon-minus" @click="setPreviousSeason"></el-button>
                <el-button type="primary" icon="el-icon-plus" @click="setNextSeason"></el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
    <el-row>
      <el-col>
        <el-descriptions class="margin-top" :column="3" border v-if="apartmentList">
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-data-line"></i>
              Totale Consumi Condominio
            </template>
            {{ Math.floor(totalConsumption) }}
          </el-descriptions-item>
          <!--          <el-descriptions-item>-->
          <!--            <template #label>-->
          <!--              <i class="el-icon-data-line"></i>-->
          <!--              Media Consumi Condominio-->
          <!--            </template>-->
          <!--            {{ averageConsumption.toFixed(2) }}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template #label>-->
          <!--              <i class="el-icon-data-line"></i>-->
          <!--              K Totale-->
          <!--            </template>-->
          <!--            {{ totalK }}-->
          <!--          </el-descriptions-item>-->
        </el-descriptions>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="apartmentList">
        <el-table
        :data="apartmentList.filter( (ap) =>
          !query || ap.description.toLowerCase().includes(query.toLowerCase())
        )">
          <!-- <el-table-column prop="id" label="ID" width="100"></el-table-column> -->
          <el-table-column prop="number" label="Numero"></el-table-column>
          <el-table-column prop="floor" label="Piano/Scala"></el-table-column>
          <el-table-column v-if="!self" prop="description" label="Descrizione">
            <template #default="scope">
              {{ scope.row.description }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="relationCode" label="Codice associazione"></el-table-column> -->
          <el-table-column v-if="self" prop="condominium" label="Condominio">
            <template #default="scope">            
              {{ stringifyAddress(scope.row.condominium.address) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!self" prop="user" label="Utente">
            <template #default="scope">
              {{ getFullName(getLastTenant(scope.row.tenants)) }}
            </template>
          </el-table-column>
          <el-table-column prop="targetComsumption" label="Consumo ideale" align="right">
            <template #default="scope">
              {{ parseFloat((100 * scope.row.targetComsumption).toFixed(2)) }}%
            </template>
          </el-table-column>
          <el-table-column prop="consumption" label="Consumi" align="right">
            <template #default="scope">
              {{ parseFloat(Math.floor(scope.row.consumption)) }}
            </template>
          </el-table-column>
          <el-table-column prop="effectiveConsumption" label="Consumi%" align="right">
            <template #default="scope">
              {{ parseFloat((100 * scope.row.effectiveConsumption).toFixed(2)) }}%
              <el-tooltip class="item" effect="dark"
                          :content="'Scostamento da consumo ideale: ' + (100 * scope.row.difference).toFixed(2) + '%'">
                <i :class="getIcon(scope.row.difference)+' consumption-icon'"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="Clicca per visualizzare le info dell'appartamento">
                <el-button @click="apartmentSelected(scope.row)" type="primary" circle size="small"
                           icon="el-icon-d-arrow-right"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AddressMixin from "../mixins/AddressMixin";
import UserMixin from "../mixins/UserMixin";
import IconsMixin from "../mixins/IconsMixin";
import {mapActions, mapGetters} from "vuex";
import DateMixin from "../mixins/DateMixin";
import moment from "moment";
import {useReadingsStore} from "@/store/useReadingsStore";
import {storeToRefs} from "pinia";
import {computed} from "vue";

export default {
  name: "ApartmentList",
  components: {},
  mixins: [AddressMixin, UserMixin, IconsMixin, DateMixin],
  data() {
    const readingsStore = useReadingsStore()
    const {selectedPeriod} = storeToRefs(readingsStore)
    const period = computed({
      get() {
        return [selectedPeriod.value.start.format("DD/MM/YYYY"), selectedPeriod.value.end.format("DD/MM/YYYY")]
      },
      set([start, end]) {
        selectedPeriod.value.start = moment(start, "DD/MM/YYYY");
        selectedPeriod.value.end = moment(end, "DD/MM/YYYY");
      }
    })
    return { 
      selectedPeriod,
      lastYearPeriod: readingsStore.lastYearPeriod,
      period,    
      apartmentList: [],
      self: true,
      totalConsumption: 0,
      averageConsumption: 0,
      totalK: 0,
      query: ""
    }
  },
  computed: {
    ...mapGetters(["currentCondominium", "currentUser", "basicUserList", "appUser", "isGranted", "currentApartmentReadingTotals", "currentApartment"])
  },
  methods: {
    ...mapActions(["fetchAllBasicUsers", "createApartment", "fetchApartment", "fetchCondominium", "fetchReadings"]),
    apartmentSelected(e) {
      this.$router.push("/dashboard/appartamento/" + e.id);
    },
    getLastTenant(tenants) {
      if (!tenants?.length) return null;
      return tenants.find(t => t.endDate === null);
    },

    async fetchInitialData() {
      let apartmentId = this.$route.params.id;
      if (!apartmentId) {
        await this.$router.push({name: "dashboard"});
        return
      }
      if (!this.currentApartment || this.currentApartment.id !== apartmentId) {
        await this.fetchApartment(apartmentId);
      }

      // SETUP READINGS PERIOD
      const condominium = this.currentApartment.condominium;
      this.currentCondominium = condominium;
      

      this.selectedPeriod.start = moment(moment().year() + "-" + this.currentCondominium.readingsStartDate)
      if (moment().isBefore(this.selectedPeriod.start)) {
        this.selectedPeriod.start.subtract(1, "year")
      }
      this.selectedPeriod.end = moment(moment().year() + "-" + this.currentCondominium.readingsEndDate)
      if (this.selectedPeriod.end.isBefore(this.selectedPeriod.start)) {
        this.selectedPeriod.end.add(1, "year")
      }
      this.fetchData();

    },
    async createData() {
if (this.currentCondominium) {
        this.apartmentList = this.currentCondominium.apartments;
        this.self = false;
      }
      //calcolo consumi
      //this.totalConsumption = this.currentCondominium.totalConsumption;
      this.totalK = this.currentCondominium.totalK;

      this.apartmentList.forEach(apartment => {
        let year = moment().year();
        if (moment().isBefore(moment().year() + "-" + this.currentCondominium.readingsEndDate)) {
          year = moment().subtract(1, 'year').year();
        }
        if (
            moment(apartment.lastReadingDate).isBefore(
                moment(year + "-" + this.currentCondominium.readingsStartDate))
        ) {
          apartment.consumption = 0;
        }
        this.totalConsumption += apartment.consumption

      })
      this.averageConsumption = this.totalConsumption / this.apartmentList.length;

      // Calcolo Valori in norma
      this.apartmentList.forEach(apartment => {
        if (this.totalK > 0 && this.totalConsumption > 0) {
          // Se il consumo è della stagione precedente viene impostato a 0.
          apartment["targetComsumption"] = apartment.kApartment / this.totalK;
          apartment["effectiveConsumption"] = apartment.consumption / this.totalConsumption;
          apartment["difference"] = (apartment["effectiveConsumption"] - apartment["targetComsumption"]) / apartment["targetComsumption"];
        } else {
          apartment["targetComsumption"] = 0;
          apartment["effectiveConsumption"] = 0;
          apartment["difference"] = 0;
        }
      });
    },
    setPreviousSeason() {
      this.selectedPeriod = {
        start: this.selectedPeriod.start.subtract(1, "year"),
        end: this.selectedPeriod.end.subtract(1, "year"),
      }
      this.fetchData()
    },
    setNextSeason() {
      this.selectedPeriod = {
        start: this.selectedPeriod.start.add(1, "year"),
        end: this.selectedPeriod.end.add(1, "year"),
      }
      this.fetchData()
    },
    fetchData() {
      useReadingsStore().fetchReadings(this.currentApartment.id, {
        startDate: this.selectedPeriod.start.format("YYYY-MM-DD"),
        endDate: this.selectedPeriod.end.format("YYYY-MM-DD")
      })
      if (this.compareMode) {
        this.fetchLastYearData();
      }
    },
    getLastReadingDate() {
      if (
          moment(this.currentCondominium.lastReadingDate).isBefore(
              moment(moment().year() + "-" + this.currentCondominium.readingsStartDate))
      ) {
        return moment(moment().year() + "-" + this.currentCondominium.readingsStartDate).format("DD/MM/YYYY")
      }
      return this.formatDate(this.currentCondominium.lastReadingDate)
    }
  },
  fetchLastYearData() {
      useReadingsStore().fetchReadings(this.currentApartment.id, {
        startDate: this.lastYearPeriod.start.format("YYYY-MM-DD"),
        endDate: this.lastYearPeriod.end.format("YYYY-MM-DD")
      })
    },
  async created() {
    await this.createData();
    this.emitter.on('update-readings', () => {
      this.fetchData();
    })
  },
  async mounted() {
    await this.fetchInitialData();
  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        await this.fetchCondominium(this.$route.params.id);
        await this.createData()
        await this.fetchInitialData()
      }
      //this.updateView = !this.updateView
    }
  }
}
</script>

<style scoped>
.color-danger {
  color: #ff3535
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}

.consumption-icon {
  font-weight: 1000;
}
</style>
