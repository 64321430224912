<template>
  <el-dialog :title="title" v-model="dialog" :close-on-click-modal="false">
    <el-row>
      <el-col :span="8">
        <span>Giorno</span>
        <el-select v-model="dateDay" placeholder="Seleziona giorno">
          <el-option
              v-for="day in 31"
              :label="day"
              :key="day"
              :value="day"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1"></el-col>

      <el-col :span="8">
        <span>Mese</span>
        <el-select v-model="dateMonth" placeholder="Seleziona mese">
          <el-option
              v-for="month in monthList"
              :label="month"
              :key="month"
              :value="month"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">Annulla</el-button>
        <el-button type="primary" @click="submit">Conferma</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import DateMixin from "../../mixins/DateMixin";

export default {
  name: "DatePickerDialog",
  mixins: [DateMixin],
  emits: ["submit", "dismiss"],
  data(){
    return{
      dialog: false,
      title: "",
      dateDay: 1,
      dateMonth: "Set"
    }
  },
  methods: {
    submit(){
      this.$emit("submit", this.getCorrectStartDate());
      this.dialog = false;
    },
    dismiss(){
      this.$emit("dismiss");
      this.dialog = false;
    },
    getCorrectStartDate(){
      return (this.monthList.findIndex((el) => (el === this.dateMonth))+1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      }) + "-" + this.dateDay.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
    },
  }
}
</script>

<style scoped>

</style>
