<template>
  <div>
    <condominium-info ref="condominiumInfo" :key="currentCondominium" v-if="currentCondominium"></condominium-info>
    <p></p>
    <el-card
        v-if="(isGranted('ROLE_CONDO') ||  (isOwner(currentUser, currentCondominium) || isCondominiumAdministrator(currentUser, currentCondominium) || isSupplier(currentUser, currentCondominium) || isServiceTechnician(currentUser, currentCondominium))) && currentCondominium">
      <h4><span class="el-icon-setting"></span> Operazioni </h4>
      <div
          v-if="isGranted('ROLE_CONDO') || isOwner(currentUser, currentCondominium) || isCondominiumAdministrator(currentUser, currentCondominium) || isSupplier(currentUser, currentCondominium)">
        <!--        <el-button type="primary" @click="printReadings"><i class="el-icon-download"></i>{{ $t("buttons.condominium.printReadings") }} </el-button>-->
        <el-button type="primary" @click="openPrintReadingsDialog"><i class="el-icon-download"></i>{{
            $t("buttons.condominium.printReadings")
          }}
        </el-button>
        <el-button disabled type="primary"><i class="el-icon-phone-outline"></i> {{
            $t("buttons.condominium.assistance")
          }}
        </el-button>
        <el-button type="primary" @click="dialogPrintUsers = true"><i class="el-icon-view"></i>
          {{ $t("buttons.condominium.printUsers") }}
        </el-button>
      </div>

      <h4><span class="el-icon-lock"></span> Amministrazione </h4>
      <el-button
          v-if="isGranted('ROLE_MANAGER') || (isOwner(currentUser, currentCondominium) || isCondominiumAdministrator(currentUser, currentCondominium) || isSupplier(currentUser, currentCondominium))"
          icon="el-icon-plus" type="primary" @click="openCreateApartmentDialog">
        Aggiungi Appartamento
      </el-button>
      <el-button v-if="isGranted('ROLE_MANAGER') || isServiceTechnician(currentUser, currentCondominium)"
                 icon="el-icon-edit-outline" type="primary" @click="openReplaceMetersDialog">
        Sostituzione contatori
      </el-button>
      <template v-if="isGranted('ROLE_MANAGER')">

        <el-button v-if="currentCondominium.active" icon="el-icon-remove" type="warning"
                   @click="openDisableCondoDialog">
          Disattiva Condominio
        </el-button>
        <el-button v-else icon="el-icon-unlock" type="warning" @click="openDisableCondoDialog">
          Attiva Condominio
        </el-button>
      </template>

    </el-card>
    <p></p>

    <el-card v-if="currentCondominium" class="box-card">
      <apartment-list :key="currentCondominium.apartments"></apartment-list>
    </el-card>
    <form-dialog
        v-if="isGranted('ROLE_MANAGER') || isOwner(currentUser, currentCondominium) || isCondominiumAdministrator(currentUser, currentCondominium) || isSupplier(currentUser, currentCondominium)"
        ref="createApartmentDialog" :fields="newApartmentFields"
        :title="'Crea Appartamento'" @submit="submittedCreateApartment"></form-dialog>
    <confirm-dialog ref="confirmDialog" @submit="submitDisableCondo"></confirm-dialog>
    <!--    todo separare componente-->
    <el-dialog v-model="dialogPrintUsers">
      <el-button primary @click="printCsv"> Scarica csv</el-button>
      <el-table :data="csvApartmentsData">
        <el-table-column border="true" :key="key" v-for="(label, key) in printUserFields" :prop="label" :label="label">
          <template #default="scope"> {{
              scope.row[key]
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <show-unactive-users-dialog ref="showUnactiveUsersDialog"></show-unactive-users-dialog>
    <replace-meters-dialog ref="replaceMetersDialog"></replace-meters-dialog>
    <print-readings-dialog ref="printReadingsDialog"></print-readings-dialog>
  </div>
</template>

<script>
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";
import FormDialog from "../../components/Dialogs/FormDialog";
import {mapActions, mapGetters} from "vuex";
import ApartmentList from "../../components/ApartmentList";
import {getNewApartmentFields} from "../../static/formFields";
import DateMixin from "../../mixins/DateMixin";
import ReplaceMetersDialog from "../../components/Dialogs/ReplaceMetersDialog";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import CondominiumInfo from "../../components/CondominiumInfo";
import ExcelMixin from "../../mixins/ExcelMixin";
import PrintReadingsDialog from "../../components/Dialogs/PrintReadingsDialog";
import ShowUnactiveUsersDialog from "../../components/Dialogs/ShowUnactiveUsersDialog.vue";
import UtilsMixin from "@/mixins/UtilsMixin";


export default {
  name: "Condominium",
  mixins: [UserMixin, AddressMixin, DateMixin, ExcelMixin, UtilsMixin],
  components: {
    PrintReadingsDialog,
    CondominiumInfo,
    ConfirmDialog,
    ApartmentList, FormDialog, ReplaceMetersDialog, ShowUnactiveUsersDialog
  },
  data() {
    return {
      newApartmentFields: [],
      hasRole: {
        ROLE_MANAGER: false,
        ROLE_ADMIN: false,
        ROLE_CONDO: false,
      },
      usersUnactive: false,
      dialogDisableCondo: false,
      activeStatus: true,
      dialogPrintUsers: false,
      dialogPrintUnactiveUser: false,
      printUserFields: {
        "floor": "Piano",
        "number": "Numero",
        "name": "Nominativo",
        "description": "Descrizione",
        "relationCode": "Codice associazione",
      },
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentCondominium",
      "appUser",
      "isGranted",
      "condominiumReadings",
      "unactiveUserList",
    ]),
    csvApartmentsData(){
      return this.currentCondominium.apartments.map(apartment => ({
        floor: apartment.floor,
        number: apartment.number,
        name: this.getCurrentTenantName(apartment),
        description: apartment.description,
        relationCode: apartment.relationCode
      }))
    }
  },
  methods: {
    ...mapActions(["fetchUser", "fetchCondominium", "fetchAllBasicUsers", "createApartment", "disableCondominium", "activateCondominium", "fetchCondominiumReadings", "fetchUnactiveUsers"]),

    async openCreateApartmentDialog() {
      this.newApartmentFields = getNewApartmentFields();
      this.$refs.createApartmentDialog.show();
    },
    openDisableCondoDialog() {
      this.$refs.confirmDialog.title = this.$t("condominium.disable.confirmDialog.title");
      if (this.currentCondominium.active) {
        this.$refs.confirmDialog.message = this.$t("condominium.disable.confirmDialog.messageDisable");
      } else {
        this.$refs.confirmDialog.message = this.$t("condominium.disable.confirmDialog.messageEnable");
      }
      this.$refs.confirmDialog.show();
    },
    async submitDisableCondo() {
      if (this.currentCondominium.active) {
        await this.disableCondominium();
      } else {
        await this.activateCondominium();
      }
      this.descriptionFields.forEach(obj => {
        if (obj.field === "active") {
          obj.value = this.getStatusText()
        }
      })
    },
    async submittedCreateApartment(data) {
      await this.createApartment({...data});
    },

    //todo sposta
    printCsv() {
      const separator = ";";
      const headers = "data:text/csv;charset=utf-8," + Object.values(this.printUserFields).join(separator);
      let csv = headers + "\r\n";

      this.csvApartmentsData.forEach((apartment) => {
        let row = "";
        Object.keys(this.printUserFields).forEach((field) => {
          row += apartment[field] + separator;
        })
        csv += row + "\r\n";
      })
      let encodedUri = encodeURI(csv);
      let downloadLink = document.createElement("a");
      downloadLink.href = encodedUri;
      downloadLink.download = this.currentCondominium.description + ".csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    },
    getConfirm() {
      if (this.hasEditedFields)
        return 'Are you sure you want to leave the current page? \n(All unsaved changes will be lost)';
    },
    openReplaceMetersDialog() {
      this.$refs.replaceMetersDialog.show();
    },
    openPrintReadingsDialog() {
      this.$refs.printReadingsDialog.show();
    },

  },
  async created() {
    await this.fetchCondominium(this.$route.params.id);
    this.apartments = !this.apartments;
    this.$refs.condominiumInfo.createFields();
    window.onbeforeunload = () => {
      return this.getConfirm();
    };
  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        const condoId = this.$route.params.id;
        if (condoId) {
          await this.fetchCondominium(condoId);
          this.$refs.condominiumInfo.createFields();
        }
      }
    }
  }
}
</script>

<style>
.table-fixed .el-descriptions__body table {
  table-layout: fixed;
}

.el-button {
  margin: 5px 0;
}

.el-descriptions {
  margin: 20px 0;
}
</style>