import moment from "moment";
import UserMixin from "./UserMixin";
import AddressMixin from "./AddressMixin";
import {mapActions, mapGetters} from "vuex";
import DateMixin from "./DateMixin";

const ReadingMixin = {
    mixins: [UserMixin, AddressMixin, DateMixin],
    data() {
        return {
            //tolleranza correttezza ultima data
            tolerance: 15
        }
    },
    computed: {
        ...mapGetters(["meterConsumption", 'currentApartment', 'condominiumDomesticUsers'])
    },
    methods: {
        ...mapActions(["fetchMeterConsumption", 'fetchApartment', 'fetchCondominiumDomesticUsers']),
        getTenantName: apartment => {
            let tenant = apartment?.tenants?.find(tenant => tenant.endDate === null)
            if (tenant) {
                return tenant.name ?? "" + " " + tenant.surname ?? ""
            }

            return null;
        },
        async createReadingsDataset(condominium, readings, query) {
            let dataset = {};
            //Condominium info
            dataset["description"] = condominium["description"]
            dataset["address"] = this.stringifyAddress(condominium.address)
            dataset["fiscalCode"] = condominium["fiscalCode"]
            dataset["meterBrand"] = ""//todo
            dataset["meterType"] = "Ripartitore" //todo
            dataset["transmissionType"] = condominium.transmissionType ? "Scuro" : "Chiaro"
            //todo

            const imgSource = this.getCondominiumImg(condominium)
            if (imgSource) {
                const [, type] = imgSource.split(';')[0].split('/');
                dataset["img"] = {
                    data: imgSource,
                    format: type
                }
            }


            dataset["anomalies"] = []

            dataset["totConsumption"] = 0
            //apartments
            dataset["apartments"] = []
            condominium.apartments.forEach(apartment => {
                if (apartment.meters?.length > 0) {
                    dataset.apartments[apartment.id] = {}
                    dataset.apartments[apartment.id]["number"] = apartment.number
                    dataset.apartments[apartment.id]["description"] = this.getTenantName(apartment) ?? apartment.description
                    dataset.apartments[apartment.id]["kApartment"] = apartment.kApartment
                    dataset.apartments[apartment.id]["totConsumption"] = 0

                    //meters

                    dataset.apartments[apartment.id]["meters"] = [];
                    apartment.meters?.forEach(async meter => {
                        //salto i contatori sostituiti prima della data
                        if (meter.replacementDate === null || moment(meter.replacementDate).isAfter(query.startDate)) {
                            dataset.apartments[apartment.id].meters[meter.id] = {}
                            dataset.apartments[apartment.id].meters[meter.id]["id"] = meter.id
                            dataset.apartments[apartment.id].meters[meter.id]["description"] = meter.description
                            dataset.apartments[apartment.id].meters[meter.id]["serialNumber"] = meter.serialNumber
                            dataset.apartments[apartment.id].meters[meter.id]["kMeter"] = meter.kMeter || 1

                            //letture
                            let prevReading = readings[apartment.id][meter.id].readings[0]
                            let lastReading = readings[apartment.id][meter.id].readings[readings[apartment.id][meter.id].readings.length - 1]

                            //check azzeramento
                            if (prevReading && prevReading.value < lastReading.value) {
                                dataset.apartments[apartment.id].meters[meter.id]["previousReading"] = readings[apartment.id][meter.id].readings[1].value;
                                dataset.apartments[apartment.id].meters[meter.id]["previousReadingDate"] = moment(readings[apartment.id][meter.id].readings[1].dateTime);
                            } else {
                                dataset.apartments[apartment.id].meters[meter.id]["previousReading"] = 0
                                // dataset["anomalies"].push({
                                //     apartmentNumber: apartment.number,
                                //     apartmentDescription: dataset.apartments[apartment.id]["description"],
                                //     meterId: dataset.apartments[apartment.id].meters[meter.id]["id"],
                                //     meterDescription: meter.description,
                                //     meterSerialNumber: meter.serialNumber,
                                //     date: query["startDate"] || "error",
                                //     description: "Non sono presenti letture precedenti alla data " + this.date
                                // })
                            }

                            if (lastReading) {
                                let endDateMoment = moment(query.endDate, "MM/DD/YYYY")
                                let lastReadingMoment = moment(lastReading.date)

                                if (endDateMoment.diff(lastReadingMoment, 'days') > 15) {
                                    dataset["anomalies"].push({
                                        apartmentNumber: apartment.number,
                                        apartmentDescription: dataset.apartments[apartment.id]["description"],
                                        meterId: dataset.apartments[apartment.id].meters[meter.id]["id"],
                                        meterDescription: meter.description,
                                        meterSerialNumber: meter.serialNumber,
                                        date: query["startDate"] || "error",
                                        description: "La lettura dell'ultimo periodo non è presente, consumo autocalcolato"
                                    })
                                    //todo calcolare consumo stimato
                                    dataset.apartments[apartment.id].meters[meter.id]["lastReading"] = lastReading.value
                                    dataset.apartments[apartment.id].meters[meter.id]["lastReadingDate"] = moment(lastReading.dateTime)

                                } else {
                                    dataset.apartments[apartment.id].meters[meter.id]["lastReading"] = lastReading.value
                                    dataset.apartments[apartment.id].meters[meter.id]["lastReadingDate"] = moment(lastReading.dateTime)
                                }

                            } else {
                                dataset.apartments[apartment.id].meters[meter.id]["lastReading"] = 0
                                dataset["anomalies"].push({
                                    apartmentNumber: apartment.number,
                                    apartmentDescription: dataset.apartments[apartment.id]["description"],
                                    meterId: dataset.apartments[apartment.id].meters[meter.id]["id"],
                                    meterDescription: meter.description,
                                    meterSerialNumber: meter.serialNumber,
                                    date: query["startDate"] || "error",
                                    description: "Ultima lettura non ricevuta " + this.date ?? ""
                                })
                            }

                            dataset.apartments[apartment.id].meters[meter.id]["diffReading"] = dataset.apartments[apartment.id].meters[meter.id]["lastReading"] - dataset.apartments[apartment.id].meters[meter.id]["previousReading"]
                            dataset.apartments[apartment.id].meters[meter.id]["consumption"] = dataset.apartments[apartment.id].meters[meter.id]["diffReading"] * dataset.apartments[apartment.id].meters[meter.id]["kMeter"]


                            //consumo appartamento
                            dataset.apartments[apartment.id].totConsumption += dataset.apartments[apartment.id].meters[meter.id]["consumption"]
                            //todo ottimizza
                            await this.checkLastYearConsumption(dataset, apartment.id, meter.id, query.endDate);
                            this.checkNoConsumption(dataset, apartment.id, meter.id, query.endDate);

                        }

                    })
                    dataset.totConsumption += dataset.apartments[apartment.id].totConsumption
                }
            })

            //calcolo percentuali
            dataset.apartments.forEach(apartment => {
                apartment["totPercentage"] = apartment.totConsumption / dataset.totConsumption * 100
            })

            //aggiunta errori letture
            this.checkReadingErrors(dataset, readings)

            //aggiunta cambi utenze
            await this.fetchCondominiumDomesticUsers({startDate: query.startDate, endDate: query.endDate});
            dataset['userChanges'] = [];
            console.log(this.condominiumDomesticUsers)
            Object.values(this.condominiumDomesticUsers).forEach(list => {
                list.forEach(domesticUser => {
                    let obj = {
                        apartmentNumber: domesticUser.apartment.number,
                        floor: domesticUser.apartment.floor,
                        description: domesticUser.apartment.description,
                        date: this.formatDate(domesticUser.endDate),
                        user: this.getFullName(domesticUser.user)
                    }
                    dataset.userChanges.push(obj);
                })
            });


            //aggiunta cambi ripartitori

            return dataset
        },

        async checkLastYearConsumption(dataset, apartmentId, meterId, endDate) {

            const apartment = dataset.apartments[apartmentId]
            const meter = apartment.meters[meterId];
            await this.fetchMeterConsumption({'serial': meter.serialNumber, 'date': endDate});
            if (!this.meterConsumption) {
                return
            }

            let lastYearConsumption = this.meterConsumption
            let diff = Math.abs(meter.consumption - lastYearConsumption)
            let tolerance = diff * 1.2

            if (lastYearConsumption > tolerance) {
                let strangeConsumption = {
                    apartmentNumber: apartment.number,
                    apartmentDescription: apartment.description,
                    meterId: meterId,
                    meterDescription: meter.description,
                    meterSerialNumber: meter.serialNumber,
                    date: endDate,
                }

                if (meter.consumption > lastYearConsumption) {
                    strangeConsumption['description'] = "Lettura non plausibile: consumo troppo basso rispetto all' anno scorso"
                } else {
                    strangeConsumption['description'] = 'Esporta letture: consumo troppo alto rispetto all anno scorso'
                }
                dataset["anomalies"].push(strangeConsumption)
            }
        },

        checkNoConsumption(dataset, apartmentId, meterId, endDate) {
            const apartment = dataset.apartments[apartmentId]
            const meter = apartment.meters[meterId];
            if (meter.consumption === 0) {
                dataset["anomalies"].push({
                    apartmentNumber: apartment.number,
                    apartmentDescription: apartment.description,
                    meterId: meterId,
                    meterDescription: meter.description,
                    meterSerialNumber: meter.serialNumber,
                    date: endDate,
                    description: 'Lettura non plausibile: consumo a 0'
                })
            }
        },

        checkBiggestConsumption(dataset) {
            let biggest = 0, second = 0;
            let biggestApartment = dataset.apartments[0]
            dataset.apartments.forEach(apartment => {
                if (apartment.totConsumption > biggest) {
                    second = biggest
                    biggest = apartment.totConsumption
                }
            })

            if (biggest - second > second * 0.3) {
                dataset["anomalies"].push({
                    apartmentNumber: biggestApartment.number,
                    apartmentDescription: biggestApartment.description,
                    meterId: '',
                    meterDescription: '',
                    meterSerialNumber: '',
                    date: '',
                    description: 'Lettura non plausibile: consumo troppo alto rispetto al resto'
                })
            }
        },

        checkReadingErrors(dataset, readings) {
            dataset.apartments.forEach((ap, apartmentId) => {
                ap.meters.forEach(meter => {
                        readings[apartmentId][meter.id].readings.forEach(reading => {
                            if (reading && reading.error !== null && reading.error !== '') {
                                dataset["anomalies"].push({
                                    apartmentNumber: ap.number,
                                    apartmentDescription: ap.description,
                                    meterId: meter.id,
                                    meterDescription: meter.description,
                                    meterSerialNumber: meter.serialNumber,
                                    date: reading.date, //todo moment
                                    description: 'Errore lettura: ' + reading.error
                                })
                            }
                        })
                    }
                )
            })
        },

        getCondominiumImg(condominium) {
            let imgSource = condominium.imgSource
            if (imgSource === 'default') {
                imgSource = 'owner'
            }

            const user = condominium[imgSource];

            return user.imgData
        }
    }
}

export default ReadingMixin;