<template>
  <div>
    <el-alert v-if="hasEditedFields" type="warning" show-icon style="margin-bottom: 15px" :closable="false">
      {{ $t("alerts.unsavedChanges") }}
    </el-alert>
    <el-card class="box-card">
      <h3> {{ $t("condominium.title") }} - {{ currentCondominium.description }}</h3>

      <el-descriptions v-for="(category, key) in descriptionFields" :key="category" :column="3" border
                       class="margin-top table-fixed" direction="vertical"
                       :title='$t("condominium.fields.categories." + key)'>
        <template #extra>
          <el-button v-if="hasEditedFields" type="primary" @click="saveEdits">
            <i class="el-icon-upload"></i> {{ $t("buttons.saveUpdate") }}
          </el-button>
        </template>
        <el-descriptions-item v-for="condo in category" :key="condo.field" :span="condo.span">
          <template #label>
            <div class="justify-space-between" style="display: flex; align-items: flex-end">
              <span>
                <i :class="condo.icon"></i> {{ condo.label }}
              </span>
              <span style="float: right"
                    v-if="condo.editable && (isGranted('ROLE_MANAGER') ||
                    (isOwner(currentUser, currentCondominium) || isCondominiumAdministrator(currentUser, currentCondominium) || isSupplier(currentUser, currentCondominium)) && !isUserField(condo.field))"
                    class="pointer edit-button"
                    @click="condo.onEdit(condo)"><i class="el-icon-edit"></i>Modifica</span>
            </div>
          </template>
          <span v-if="condo.field==='transmissionType'"><i class="el-icon-s-opportunity"
                                                           :class="transmissionTypeColor"></i> </span>
          <span v-if="condo.onClick" class="pointer" @click="condo.onClick">{{ condo.value }}</span>
          <span v-else>{{ condo.value }}</span>
        </el-descriptions-item>
      </el-descriptions>

      <simple-input-dialog ref="simpleInputDialog" @submit="updateCurrentField"></simple-input-dialog>
      <form-dialog ref="addressDialog" :fields="addressFields" title="Modifica Indirizzo"
                   @submit="updateCurrentAddress"></form-dialog>
      <simple-select-dialog ref="simpleSelectDialog" @submit="updateCurrentSelect"></simple-select-dialog>
      <date-picker-dialog ref="datePickerDialog" @submit="updateCurrentDate"></date-picker-dialog>
    </el-card>
  </div>
</template>

<script>
import SimpleInputDialog from "./Dialogs/SimpleInputDialog";
import FormDialog from "./Dialogs/FormDialog";
import SimpleSelectDialog from "./Dialogs/SimpleSelectDialog";
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../mixins/UserMixin";
import AddressMixin from "../mixins/AddressMixin";
import DateMixin from "../mixins/DateMixin";
import {getAddressFields, getTransmissionTypes} from "../static/formFields";
import DatePickerDialog from "./Dialogs/DatePickerDialog";


export default {
  name: "CondominiumInfo",
  mixins: [UserMixin, AddressMixin, DateMixin],
  components: {DatePickerDialog, SimpleSelectDialog, FormDialog, SimpleInputDialog},
  data() {
    return {
      descriptionFields: {},
      imgSourceOptions: {
        "default": "Default",
        "owner": "Cliente Sunny",
        "condominiumAdministrator": "Amministratore Condominiale",
        "supplier": "Gestore Calore/Acqua",
        "serviceTechnician": "Ditta Assistenza Tecnica"
      },
      hasEditedFields: false,
      editedFields: {},
      currentField: null,
      addressFields: {},
    }
  },
  computed: {
    ...mapGetters([
      "currentCondominium",
      "currentUser",
      "isGranted",
      "condominiumAdministratorList"

    ]),
    transmissionTypeColor() {
      if (this.currentCondominium.transmissionType)
        return "dark-blue";
      else {
        return "light-yellow";
      }
    }
  },
  methods: {
    ...mapActions(["fetchAllCondominiumAdministrators", "updateCondominium"]),
    isUserField(field) {
      return ["owner", "condominiumAdministrator", "supplier", "serviceTechnician"].includes(field)
    },
    createFields() {
      this.descriptionFields["system"] = [
        {
          field: "owner",
          value: this.getFullName(this.currentCondominium.owner),
          label: "Cliente Sunny",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.owner)
        }, {
          field: "condominiumAdministrator",
          value: this.getFullName(this.currentCondominium.condominiumAdministrator),
          label: "Amministratore di Condominio",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.condominiumAdministrator)
        }, {
          field: "supplier",
          value: this.getFullName(this.currentCondominium.supplier),
          label: "Gestore Calore/Acqua",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.supplier)
        }, {
          field: "serviceTechnician",
          value: this.getFullName(this.currentCondominium.serviceTechnician),
          label: "Ditta Assistenza Tecnica",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editSelect,
          onClick: () => this.goToUser(this.currentCondominium.serviceTechnician)
        }, {
          field: "imgSource",
          value: this.stringifyImgSource(this.currentCondominium.imgSource),
          label: "Immagine da visualizzare",
          icon: "el-icon-picture",
          span: 1,
          editable: true,
          onEdit: this.editImgSource
        },
      ];

      this.descriptionFields["registry"] = [
        {
          field: "description",
          value: this.currentCondominium.description,
          label: "Denominazione",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "descriptionShort",
          value: this.currentCondominium.descriptionShort,
          label: "Denominazione in breve",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "fiscalCode",
          value: this.currentCondominium.fiscalCode,
          label: "Codice Fiscale",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "address",
          value: this.stringifyAddress(this.currentCondominium.address),
          label: "Indirizzo",
          icon: "el-icon-location-outline",
          span: 3,
          editable: true,
          onEdit: this.editAddress,
        }
      ];

      this.descriptionFields["technical"] = [
        {
          field: "readingsStartDate",
          value: this.stringifyReadingDate(this.currentCondominium.readingsStartDate),
          label: "Data Inizio Stagione",
          icon: "el-icon-date",
          span: 1,
          editable: true,
          onEdit: this.editReadingStartDate
        }, {
          field: "readingsEndDate",
          value: this.stringifyReadingDate(this.currentCondominium.readingsEndDate),
          label: "Data Fine Stagione",
          icon: "el-icon-date",
          span: 1,
          editable: true,
          onEdit: this.editReadingEndDate
        }, {
          field: "active",
          value: this.stringifyStatus(),
          label: "Stato",
          icon: "el-icon-info",
          span: 1,
          editable: false,
        },
        {
          field: "transmissionType",
          value: this.stringifyTransmissionType(this.currentCondominium.transmissionType),
          label: "Tipo di trasmissione",
          icon: "el-icon-s-opportunity",
          span: 1,
          editable: true,
          onEdit: this.editTransmisionType,
        },

      ];
    },

    stringifyImgSource(imgSource) {
      if (!imgSource || imgSource === "default") {
        return "Default: Sunny";
      }
      return this.imgSourceOptions[imgSource] + ": " + this.getFullName(this.currentCondominium[imgSource]);
    },
    stringifyStatus() {
      return this.currentCondominium.active ? "ATTIVO" : "DISATTIVATO"
    },
    stringifyTransmissionType(type) {
      if (type) {
        return "Scuro";
      } else {
        return "Chiaro"
      }
    },
    async goToUser(user) {
      await this.$router.push("/dashboard/utente/" + user.id);
    },
    //edit methods
    async editSelect(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.title = field.label;
      this.$refs.simpleSelectDialog.formData = field.value;
      await this.fetchAllCondominiumAdministrators();

      this.$refs.simpleSelectDialog.items = this.condominiumAdministratorList.map(el => ({
        value: el.id,
        label: this.getFullName(el)
      }))
      this.$refs.simpleSelectDialog.show();
    },
    editReadingStartDate(field) {
      this.currentField = field;
      this.$refs.datePickerDialog.title = "Scegli la data inizio stagione";
      this.$refs.datePickerDialog.dateDay = 1
      this.$refs.datePickerDialog.dateMonth = "Set"
      this.$refs.datePickerDialog.dialog = true;
      this.$refs.datePickerDialog.date = this.currentCondominium.readingsStartDate;
    },
    editReadingEndDate(field) {
      this.currentField = field;
      this.$refs.datePickerDialog.title = "Scegli la data fine stagione";
      this.$refs.datePickerDialog.dateDay = 30
      this.$refs.datePickerDialog.dateMonth = "Giu"
      this.$refs.datePickerDialog.dialog = true;
      this.$refs.datePickerDialog.date = this.currentCondominium.readingsEndDate;
    },
    editImgSource(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.title = field.label;
      this.$refs.simpleSelectDialog.formData = field.value;
      this.$refs.simpleSelectDialog.items = Object.entries(this.imgSourceOptions).map((el) => ({
        value: el[0],
        label: this.stringifyImgSource(el[0])
      }));
      this.$refs.simpleSelectDialog.show();
    },
    editField(field) {
      this.currentField = field;
      this.$refs.simpleInputDialog.title = field.label;
      this.$refs.simpleInputDialog.formData = field.value;
      this.$refs.simpleInputDialog.show();
    },
    editAddress(field) {
      this.currentField = field;
      this.addressFields = getAddressFields(this.getAddress());
      this.$refs.addressDialog.show();
    },
    editTransmisionType(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.title = field.label;
      this.$refs.simpleSelectDialog.formData = field.value;
      this.$refs.simpleSelectDialog.items = {...getTransmissionTypes()};
      this.$refs.simpleSelectDialog.show();
    },

    //update methods
    async updateCurrentSelect(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      if (this.currentField.field === 'transmissionType') {
        this.editedFields[this.currentField.field] = (data === 'Chiaro' ? 0 : 1);
        this.currentField.value = data;
        return;
      }
      this.currentField.value = this.getFullName(this.condominiumAdministratorList.find(u => u.id === data)) || this.stringifyImgSource(data);
    },
    updateCurrentField(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = data;
    },
    updateCurrentAddress(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data.address;
      this.currentField.value = this.stringifyAddress(this.getAddress());
    },
    updateCurrentDate(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = this.stringifyReadingDate(data);
    },

    //save edits
    async saveEdits() {
      await this.updateCondominium({...this.editedFields});
      this.hasEditedFields = false;
    },

    //utility
    getAddress() {
      let address = {}
      if (this.currentCondominium) {
        address = this.currentCondominium.address;
      }

      if (this.editedFields.address) {
        Object.keys(this.editedFields.address).forEach((field) => {
          address[field] = this.editedFields.address[field]
        })
      }

      return address;
    }
  }
}
</script>

<style scoped>

.dark-blue {
  color: #0000ac;
}

.light-yellow {
  color: #ffcf42;
}

</style>