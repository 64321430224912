<template>
  <el-dialog :title="$t('condominium.readings.printReadingsDialog.title')" v-model="showDialog"
             :close-on-click-modal="false">
    <el-alert v-if="showAlert" type="warning" :title="$t('condominium.readings.printReadingsDialog.alert')"
              :closable="false"></el-alert>
    <el-row>
      <el-col :span="12">
        <h3>Data inizio</h3>
        <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="Data inizio conteggio"
            format="DD/MM/YYYY"
            value-format="DD/MM/YYYY"
            @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="12">
        <h3>Data Fine</h3>
        <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="Data fine conteggio"
            format="DD/MM/YYYY"
            value-format="DD/MM/YYYY"
            @change="changeDate"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss"> {{ $t("buttons.dismiss") }} </el-button>
        <el-button type="primary" @click="submit"> {{ $t("buttons.confirm") }} </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import DateMixin from "../../mixins/DateMixin";
import {mapActions, mapGetters} from "vuex";
import ReadingMixin from "../../mixins/ReadingMixin";
import ExcelMixin from "../../mixins/ExcelMixin";

export default {
  name: "PrintReadingsDialog",
  mixins: [DateMixin, ReadingMixin, ExcelMixin],
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      title: "",
      startDate: null,
      endDate: null,
      showAlert: false,
      dataset: null
    }
  },
  computed: {
    ...mapGetters([
      "currentCondominium",
      "condominiumReadings"
    ])
  },
  methods: {
    ...mapActions(["fetchCondominiumReadings", "startForceLoading", "stopForceLoading"]),

    async submit() {
      if (!this.showAlert) {
        let query = {
          startDate: this.startDate,
          endDate: this.endDate,
          id: this.currentCondominium.id
        }

        await this.fetchCondominiumReadings(query);

        this.startForceLoading()
        this.dataset = await this.createReadingsDataset(this.currentCondominium, this.condominiumReadings, query)
        this.stopForceLoading()
        // if (this.dataset.anomalies.length > 50){
        //   this.showAlert = true
        // } else{
        //   await this.printReadings(this.dataset);
        //   this.$emit("submit");
        //   this.showDialog = false;
        // }
        await this.printReadings(this.dataset);
        this.$emit("submit");
        this.showDialog = false;
      } else {
        await this.printReadings(this.dataset);
        this.$emit("submit");
        this.showDialog = false;
      }

    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    async printReadings(dataset) {
      await this.generateXls(dataset);
    },
    changeDate() {

      if (this.showAlert) {
        this.showAlert = false
        console.log(this.startDate, this.endDate)
      }
    }
  }
}
</script>

<style scoped>

</style>
