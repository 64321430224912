import ExcelJS from "exceljs";
import UserMixin from "./UserMixin";
import AddressMixin from "./AddressMixin";
import ReadingMixin from "./ReadingMixin";

const ExcelMixin = {
    mixins: [UserMixin, AddressMixin, ReadingMixin],
    data() {
        return {
            headers: [
                'apartmentNumber',
                'description',
                'iMeter', //progressivo 1-n ??
                'meterDescription',
                'serialNumber',
                'kApartment', //millesimi
                'previousReading', //I guess ultima lettura prima della da
                'previousReadingDate',
                'lastReading',
                'lastReadingDate',
                'diffReading',
                'kMeter',
                'consumption',
                'tot', //??????????
                'percentage',
            ],
            anomaliesHeaders: [
                "apartmentNumber",
                "apartmentDescription",
                "meterId",
                "meterDescription",
                "meterSerialNumber",
                "date",
                "description",
            ],
            userChangesHeaders: [
                'apartmentNumber',
                'floor',
                'description',
                'date',
                'user'
            ],
            startRow: 10,
            firstCol: "apartmentNumber",
            lastCol: "percentage"
        }
    },
    methods: {
        async generateXls(dataset) {
            const startRow = 10;

            const wb = new ExcelJS.Workbook();
            wb.creator = this.getFullName(this.currentUser)
            const sheet = wb.addWorksheet(dataset.description);

            //condominium info
            this.printCondominiumInfo(wb, sheet, dataset)

            let row = this.printReadingTable(dataset, startRow, sheet)

            row = this.printAnomaliesTable(dataset, row, sheet)

            this.printUserChanges(dataset, row, sheet)


            //preso da stackoverflow
            // sheet.columns.forEach(function (column/*, i*/) {
            //     let maxLength = 0;
            //     column["eachCell"]({ includeEmpty: true }, function (cell) {
            //         let columnLength = cell.value ? cell.value.toString().length : 10;
            //         if (columnLength > maxLength ) {
            //             maxLength = columnLength;
            //         }
            //     });
            //     column.width = maxLength < 10 ? 10 : maxLength;
            // });
            //end

            await this.downloadFile(wb, dataset.description);
        },

        async downloadFile(workbook, filename) {
            await workbook.xlsx.writeBuffer().then(data => {
                const blob = new Blob([data], {type: "application/vnd.ms-excel"});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename + ".xlsx";
                link.click();
            });
        },

        setHeaderStyle(cell) {
            cell.border = {
                top: {style: "thick"},
                left: {style: "thick"},
                bottom: {style: "thick"},
                right: {style: "thick"}
            };

            cell.font = {
                // name: 'Comic Sans MS',
                // family: 4,
                // size: 16,
                // underline: true,
                bold: true
            };
        },

        setRegularCellStyle(cell) {
            cell.border = {
                top: {style: "thin"},
                left: {style: "thin"},
                // bottom: {style: "thin"},
                right: {style: "thin"}
            };
        },

        setRegularRowStyle(row) {
            this.headers.forEach(header => this.setRegularCellStyle(row.getCell(header)))
            // row.getCell(this.firstCol).border.
        },

        setUpperCellBorderThick(cell) {
            if (cell.border) {
                cell.border.top = {style: "thick"}
            } else {
                cell.border = {
                    top: {style: "thick"},
                };
            }

        },

        setUpperRowBorderThick(row) {
            this.headers.forEach(header => this.setUpperCellBorderThick(row.getCell(header)))
        },

        printCondominiumInfo(wb, sheet, dataset) {
            const startRow = 2;
            let i;
            //stampo info condominio
            for (i = startRow; i < startRow + 2; i++) {
                sheet.mergeCells(i, 6, i, 13)
            }
            for (i; i < startRow + 7; i++) {
                sheet.mergeCells(i, 6, i, 7)
                sheet.mergeCells(i, 8, i, 13)
            }

            //merge celle

            sheet.getRow(2).getCell(6).value = dataset.description
            sheet.getRow(3).getCell(6).value = dataset.address
            sheet.getRow(4).getCell(6).value = "CF"
            sheet.getRow(4).getCell(8).value = dataset.fiscalCode
            sheet.getRow(5).getCell(6).value = "Marca contatore"
            sheet.getRow(5).getCell(8).value = dataset.meterBrand
            sheet.getRow(6).getCell(6).value = "Tipo contabilizzazione"
            sheet.getRow(6).getCell(8).value = dataset.meterType
            sheet.getRow(7).getCell(6).value = "Tipo installazione"
            sheet.getRow(7).getCell(8).value = dataset.transmissionType

            if (dataset.img) {
                const condominiumLogo = wb.addImage({
                    base64: dataset.img.data,
                    extension: dataset.img.format
                })
                sheet.addImage(condominiumLogo, "A2:D7")
            }

        },

        printReadingTable(dataset, startRow, sheet) {

            //associo a ogni colonna una chiave testuale corrispondente al suo header
            let columns = [];
            this.headers.forEach(header => columns.push({key: header}))
            sheet.columns = columns;


            //stampo headers
            let rowNumber = startRow;
            this.headers.forEach(header => {
                const cell = sheet.getRow(rowNumber).getCell(header)
                cell.value = this.$t("condominium.readings.headers." + header)
                this.setHeaderStyle(cell)
            });

            rowNumber++;
            this.setUpperRowBorderThick(sheet.getRow(rowNumber))
            // let totCondominium = 0;
            dataset.apartments.forEach(apartment => {
                sheet.getRow(rowNumber).getCell("apartmentNumber").value = apartment.number;
                sheet.getRow(rowNumber).getCell("description").value = apartment.description;
                sheet.getRow(rowNumber).getCell("kApartment").value = apartment.kApartment;

                apartment.meters?.forEach(meter => {
                    sheet.getRow(rowNumber).getCell("iMeter").value = meter.id;
                    sheet.getRow(rowNumber).getCell("meterDescription").value = meter.description;
                    sheet.getRow(rowNumber).getCell("serialNumber").value = meter.serialNumber;
                    sheet.getRow(rowNumber).getCell("kMeter").value = meter.kMeter;
                    sheet.getRow(rowNumber).getCell("previousReading").value = meter.previousReading;
                    sheet.getRow(rowNumber).getCell("previousReadingDate").value = meter.previousReadingDate?.format("DD/MM/YYYY");
                    sheet.getRow(rowNumber).getCell("lastReading").value = meter.lastReading;
                    sheet.getRow(rowNumber).getCell("lastReadingDate").value = meter.lastReadingDate?.format("DD/MM/YYYY");
                    sheet.getRow(rowNumber).getCell("diffReading").value = meter.diffReading;
                    sheet.getRow(rowNumber).getCell("consumption").value = meter.consumption;

                    rowNumber++;
                })
                if (rowNumber > this.startRow + 1) {
                    sheet.getRow(rowNumber - 1).getCell("tot").value = apartment.totConsumption
                    sheet.getRow(rowNumber - 1).getCell("percentage").value = apartment.totPercentage
                }

                this.setUpperRowBorderThick(sheet.getRow(rowNumber))

            })

            sheet.getRow(rowNumber).getCell("tot").value = dataset.totConsumption
            sheet.getRow(rowNumber).getCell("percentage").value = "100"

            return rowNumber;
        },
        printAnomaliesTable(dataset, row, sheet) {

            //stampo headers
            let rowNumber = row + 2;
            let i = 1
            //7 12 merge
            sheet.mergeCells(rowNumber, 7, rowNumber, 12)
            this.anomaliesHeaders.forEach(header => {
                const cell = sheet.getRow(rowNumber).getCell(i++)
                cell.value = this.$t("condominium.readings.anomaliesHeaders." + header)
                this.setHeaderStyle(cell)
            });
            rowNumber++

            dataset.anomalies.forEach(anomaly => {
                i = 1
                sheet.mergeCells(rowNumber, 7, rowNumber, 14)
                this.anomaliesHeaders.forEach(header => {
                    const cell = sheet.getRow(rowNumber).getCell(i++)
                    cell.value = anomaly[header]
                })
                rowNumber++
            })

            return rowNumber
        },

        printUserChanges(dataset, row, sheet) {

            //stampo headers
            let rowNumber = row + 2;
            let i = 1
            //7 12 merge
            // sheet.mergeCells(rowNumber, 7, rowNumber, 12)
            this.userChangesHeaders.forEach(header => {
                const cell = sheet.getRow(rowNumber).getCell(i++)
                cell.value = this.$t("condominium.readings.userChangesHeaders." + header)
                this.setHeaderStyle(cell)
            });
            rowNumber++

            dataset.userChanges.forEach(userChange => {
                i = 1
                this.userChangesHeaders.forEach(header => {
                    const cell = sheet.getRow(rowNumber).getCell(i++)

                    cell.value = userChange[header]
                })
                rowNumber++
            })
            return rowNumber;
        }
    }
}

export default ExcelMixin;