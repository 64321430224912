<template>
  <el-dialog
      v-if="currentCondominium && (isGranted('ROLE_MANAGER') || isServiceTechnician(currentUser, currentCondominium))"
      v-model="showDialog" :close-on-click-modal="false">
    <template #title>
      <h3 style="margin:0"> Procedura di sostituzione dei contatori</h3>
      <h4 style="margin:0">{{ currentCondominium.description }}</h4>
    </template>

    <div>
      <h4>Importa file csv dei contatori nuovi</h4>
      <p></p>
      <el-upload
          style="margin-top: 15px"
          class="select"
          drag
          action=""
          :on-change="handleChange"
          :on-remove="handleRemove"
          :auto-upload="false"
          accept=".csv"
          ref="upload"
          :file="file">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Trascina il file qui o <em>clicca per importare</em></div>
      </el-upload>
    </div>

    <template #footer>
      <el-button v-if="file" type="primary" @click="submit">
        <i class="el-icon-upload"></i> Carica contatori
      </el-button>
    </template>

  </el-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FileMixin from "../../mixins/FileMixin";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "ReplaceMetersDialog",
  mixins: [FileMixin, UserMixin],
  data() {
    return {
      showDialog: false,
      file: null
    }
  },
  computed: {
    ...mapGetters([
      "currentCondominium",
      "isGranted",
      "currentUser"
    ]),
  },
  methods: {
    ...mapActions(["startForceLoading", "stopForceLoading", "replaceMeterBySerial"]),
    show() {
      this.showDialog = true;
    },
    dismiss() {
      this.showDialog = false;
    },
    handleRemove(file) {
      this.file = file
    },
    clearAll() {
      this.fileList = []
      this.$refs.upload.clearFiles();
    },
    handleChange(file, filelist) {
      // if (file.raw.type!=="application/vnd.ms-excel"){
      //   this.file = null
      //   this.$message.error('File di formato non supportato');
      // } else {
      if (filelist.length > 1) {
        filelist.pop()
        filelist[0] = file;
      }

      this.file = file
      // }
    },
    async submit() {
      this.startForceLoading();

      // this.currentCondominium.apartments.forEach( (ap) => {
      //   ap.meters.forEach( async (meter) => {
      //     await this.fetchMeter(meter.id);
      //     await this.updateMeter({"active": false});
      //   })
      // });

      //trasforma file
      const text = await this.readTextFile(this.file.raw);
      const dataArray = await this.CSVToObj(text, ";");

      //crea contatori
      this.resultList = [];
      for (let row of dataArray) {
        let data = {
          oldMeter: row.oldMeter,
          serialNumber: row.serialNumber,
          brand: row.brand,
          description: row.description,
          kMeter: row.kMeter
        }
        await this.replaceMeterBySerial(data);
      }

      //chiusura dialog
      this.stopForceLoading();
      this.$message.success('Operazione completata correttamente');
      this.dismiss();
    }
  }
}
</script>

<style scoped>

</style>
